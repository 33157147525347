import React from 'react';
import {Trans, t} from '@lingui/macro';
import PropTypes from 'prop-types';
import { GLOBAL_DATA } from '../../constants/globalData';
import withDataHOC from '../dataProvider/withDataHOC';
import { createFetchAssignmentAll, createFetchAssignmentEdit } from '../../backend/apiCalls';
import Tooltip from '../general/Tooltip';
import { Checkbox, Icon, notification } from 'antd';
import { getInputOnChangeEventChecked } from '../../lib/project';
import InputNumber from '../general/InputNumber';
import InputQuantity from '../project/InputQuantity';
import { formatBoolean, formatQuantity } from '../../lib/formatting';
import AssignmentReload from '../assignments/AssignmentReload';
import BonusExplanation from '../assignments/BonusExplanation';
import { merge } from '../../lib/object';
import deepEqual from 'deep-equal';
import Price from '../general/Price';
import InputPrice from '../project/InputPrice';


/**
 * @dusan
 */

class DashboardAssignments extends React.PureComponent {
    static propTypes = {
        userId: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired,
        pin: PropTypes.string.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            assignments: [],
        }
    }

    getFetchParams = (props) => {
        const {userId, year, month, pin} = props;

        return {
            id_user: userId,
            yr: year,
            mon: month,
            pin: pin
        };
    }

    fetchAssignments = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const params = this.getFetchParams(this.props);

        if(params.pin == null)
            return;

        fetchHandler(
            createFetchAssignmentAll(),
            params,
            (result) => {
                this.setState({assignments: result});
            }
        );
    };

    componentDidMount() {
        this.fetchAssignments();
    }

    componentDidUpdate(prevProps) {
        const params = this.getFetchParams(this.props);
        const prevParams = this.getFetchParams(prevProps);

        if(!deepEqual(params, prevParams))
            this.fetchAssignments();
    }

    editItem = (params) => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler, pin} = this.props;
        const paramsChecked = merge(params, {pin: pin});

        fetchHandler(
            createFetchAssignmentEdit(),
            paramsChecked,
            null,
            this.fetchAssignments,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    };

    render() {
        const {pin} = this.props;
        const {assignments} = this.state;
        
        if(pin == null)
            return null;

        return <div className="dashboard-item">
            <div className="d-flex justify-content-between">
                <span></span>
                <h2><Trans>Aktuálne úlohy</Trans></h2>
                <AssignmentReload
                    assignments={assignments}
                    pin={pin}
                    size="small"
                    onFinish={this.fetchAssignments}
                />
            </div>
            <table className="dashboard-assignments-table">
                { assignments != null && assignments.length > 0 ? 
                    assignments.map((a, key) => {
                        return <tr key={key}>
                            <td>
                                <Tooltip title={a.task_description}>
                                    <span className="font-weight-bold mr-2">{a.task + ':'}</span>
                                </Tooltip>
                            </td>
                            <td>
                                <div>
                                    { a.is_binary ? 
                                        ( a.is_editable && a.signed_at == null ? 
                                            <Checkbox
                                                checked={a.result == 1}
                                                onChange={(e) => {
                                                    const val = getInputOnChangeEventChecked(e) ? 1 : 0;
                                                    this.editItem({id: a.id, result: val});
                                                }}
                                            >
                                                <Trans>splnené</Trans>
                                            </Checkbox> 
                                            :
                                            <span className={(a.result ? 'color-green' : 'color-red') + ' font-weight-bold'}>
                                                {a.result ? <Trans>splnené</Trans> : <Trans>nesplnené</Trans>}
                                            </span>
                                        )
                                        :
                                        ( a.is_editable && a.signed_at == null ?
                                            <InputQuantity
                                                className="text-right"
                                                value={a.result}
                                                size="small"
                                                onChange={(newVal) => {
                                                    this.editItem({id: a.id, result: newVal});
                                                }}
                                                unit={a.units}
                                            /> 
                                            :
                                            <span className={(a.dispo != null && Number(a.dispo) >= Number(a.target) ? 'color-green' : 'color-red') + ' font-weight-bold'}>
                                                {formatQuantity(a.result, a.units)}
                                            </span>
                                        )
                                    }
                                </div>
                                <div className="table-subdata">
                                    {'('}
                                    { a.is_binary ? 
                                        null : 
                                        <span className="mr-2">
                                            <Trans>plán</Trans>
                                            {': ' + formatQuantity(a.target, a.units) + ','}
                                        </span>
                                    }
                                    <span>
                                        <Trans>dispo</Trans>
                                        {': ' + formatQuantity(a.dispo, a.units)}
                                    </span>
                                    {')'}
                                </div>
                            </td>
                            <td>
                                <Trans>Odmena:</Trans>
                            </td>
                            <td style={{width: '8rem'}}>
                                <Tooltip 
                                    title={<BonusExplanation assignment={a}/>}
                                >
                                    <div className="text-right">
                                        { a.auto_bonus == 1 || a.signed_at != null ? 
                                            <Price price={a.bonus}/>
                                            :
                                            <InputPrice
                                                className="text-right"
                                                value={a.bonus}
                                                size="small"
                                                onChange={(newVal) => {
                                                    this.editItem({id: a.id, bonus: newVal});
                                                }}
                                            /> 
                                        }
                                        <div className="table-subdata text-right">
                                            {'('}
                                            <Trans>nárok</Trans>
                                            {':'}
                                            <Price price={a.bonus_available} className="ml-2"/>
                                            {')'}
                                        </div>
                                    </div>
                                </Tooltip>
                            </td>
                            <td>
                                { a.signed_at != null ? <Icon type="lock"/> : null }
                            </td>
                        </tr>
                    }) :
                    <h2 className="d-inline color-disabled">
                        <Trans>Nemáte zadané žiadne úlohy.</Trans>
                    </h2>
                }
            </table>
        </div>;
    }
}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(DashboardAssignments);