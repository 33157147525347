import React from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, LineChart, ResponsiveContainer, Line } from 'recharts';
import { formatQuantity } from '../../lib/formatting';
import Link from '../navigation/Link';
import locationHOC from '../locationProvider/locationHOC';
import { QUERY_PARAMS, ROUTES } from '../../constants/navigation';
import Tooltip from '../general/Tooltip';
import { formatDateHTML } from '../../lib/date';
import { Icon } from 'antd';
import { GLOBAL_DATA } from '../../constants/globalData';
import { createFetchDashboardItem } from '../../backend/apiCalls';
import Spinner from '../graphics/Spinner';
import withDataHOC from '../dataProvider/withDataHOC';


/**
 * @dusan
 */

class DashboardItemDisplay extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        pin: PropTypes.string.isRequired,
        userId: PropTypes.number,
        month: PropTypes.number,
        year: PropTypes.year,
        onPinFailure: PropTypes.func.isRequired,
        [GLOBAL_DATA.FETCH_DATA]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            loading: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const {pin, name, userId, month, year} = this.props;
        const {pin: prevPin, name: prevName, userId: prevUserId, month: prevMonth, year: prevYear} = prevProps;
        if(pin != prevPin || name != prevName || userId != prevUserId || month != prevMonth || year != prevYear)
            this.fetchData();
    }

    fetchData = () => {
        const {name, pin, userId, month, year, [GLOBAL_DATA.FETCH_DATA]: fetchHandler, onPinFailure} = this.props;
        this.setState({loading: true});
        fetchHandler(
            createFetchDashboardItem(),
            {name: name, pin: pin, id_user: userId, month: month, year: year},
            (result) => { this.setState({item: result}) },
            () => { this.setState({loading: false}) },
            onPinFailure
        );
    }

    trendArrow = (thisVal, prevVal, units, prevFrom, prevTo) => {
        if(thisVal == null || prevVal == null)
            return null;

        let change = Number(thisVal) - Number(prevVal);
        if(units != '%')
            change = change / Number(prevVal) * 100.0;

        if(!Number.isFinite(change))
            return null;

        return <Tooltip 
            title={t`zmena voči ${formatQuantity(prevVal, units)} za obdobie od ${formatDateHTML(prevFrom)} do ${formatDateHTML(prevTo)}`}
        >
            { change > 0.0 ?
                <Icon className='ml-2 color-green' type="caret-up"/>
                :
                <Icon className='ml-2 color-red' type="caret-down"/>
            }
            <span className={"ml-1 " + (change > 0.0 ? "color-green" : "color-red")}>
                {(change < 0 ? '' : '+') + change.toFixed(1) + '%'}
            </span>
        </Tooltip>;
    }

    render() {
        const {label, location} = this.props;
        const {item, loading} = this.state;
        const history = item.history != null ? item.history.map(s => {
            return {
                label: s.label,
                [item.label]: s.val
            };
        }) : [];
        const analysisParams = item.analysis != null ? JSON.stringify(item.analysis) : null;

        return <div className={"dashboard-item" + (history.length > 1 ? " full-size-width" : "")}>
            <div className="d-flex flex-wrap justify-content-between align-items-end">
                <h2 className="text-center">{label}</h2>
                { item.statefull ? null :
                    <div className="dashboard-sum">
                        <span className="text-secondary"><Trans>od začiatku roka:</Trans></span>
                        <span className="ml-2">{formatQuantity(item.current_year, item.units)}</span>
                        { item.target_year != null ? 
                            <span className="ml-2 table-subdata">
                                {'('}
                                <Trans>plán</Trans>
                                {': ' + formatQuantity(item.target_year, item.units) + ')'}
                            </span> : null
                        }
                        {this.trendArrow(item.current_year, item.prev_year, item.units, item.prev_year_from, item.prev_year_to)}
                    </div>
                }
                { item.statefull ? 
                    <div className="p-3 text-center">
                        <div style={{fontSize: '400%'}}>
                            {formatQuantity(item.current_month, item.units)}
                        </div>
                        { item.target_month != null ? 
                            <div className="mt-2 table-subdata">
                                {'('}
                                <Trans>plán</Trans>
                                {': ' + formatQuantity(item.target_month, item.units) + ')'}
                            </div> : null
                        }
                    </div>
                    :
                    <div className="p-2 dashboard-sum">
                        <span className="text-secondary"><Trans>tento mesiac:</Trans></span>
                        <span className="ml-2">{formatQuantity(item.current_month, item.units)}</span>
                        { item.target_month != null ? 
                            <span className="ml-2 table-subdata">
                                {'('}
                                <Trans>plán</Trans>
                                {': ' + formatQuantity(item.target_month, item.units) + ')'}
                            </span> : null
                        }
                        {this.trendArrow(item.current_month, item.prev_month, item.units, item.prev_from, item.prev_to)}
                    </div>
                }
                { analysisParams != null ? 
                    <Link
                        className="ml-3 text-dark"
                        location={location}
                        to={ROUTES.ANALYSIS}
                        queryParams={{
                            [QUERY_PARAMS.ANALYSIS_PARAMS]: analysisParams,
                            [QUERY_PARAMS.ANALYSIS_PREFETCH]: 1,
                        }}
                    >
                        <Trans>(zobraziť analýzu)</Trans>
                    </Link> : null
                }
            </div>
            { loading ? <Spinner/> : 
                <React.Fragment> 
                    
                    { history.length > 1 ? 
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart
                                data={history}
                                margin={{
                                    top: 5,
                                    right: 20,
                                    left: 15,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="label" />
                                <YAxis />
                                <ChartTooltip />
                                <Line 
                                    dataKey={item.label} 
                                    stroke="#8884d8" 
                                    strokeWidth={3}
                                    unit={item.units != null ? ' ' + item.units : null}
                                />
                            </LineChart>
                        </ResponsiveContainer> : null
                    }
                </React.Fragment>
            }
        </div>;
    }
};

export default locationHOC(
    withDataHOC([GLOBAL_DATA.FETCH_DATA])(DashboardItemDisplay)
);    