import React from 'react';
import PropTypes from 'prop-types';
import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {createSetQueryParamValue, getQueryParamNumber} from '../../lib/url';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import { InputNumber } from 'antd';
import MonthSelect from '../general/MonthSelect';
import { RIGHTS } from '../../constants/Rights';
import EmployeesSelect from '../project/EmployeesSelect';
import DashboardItemDisplay from './DashboardItemDisplay';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import NavigateButton from '../general/NavigateButton';
import DashboardAssignments from './DashboardAssignments';
import pinProtectedPageHOC from '../fetch/pinProtectedPageHOC';

/**
 * @dusan
 */

class DashboardPage extends React.PureComponent {
    static propTypes = {
        pin: PropTypes.string.isRequired,
        onPinFailure: PropTypes.func.isRequired,
        [GLOBAL_DATA.DASHBOARD_ITEMS]: PropTypes.array.isRequired,
    };

    getUserId = (props) => {
        const {location, [GLOBAL_DATA.ACCOUNT_INFO]: account} = props;
        const userId = getQueryParamNumber(location, QUERY_PARAMS.DASHBOARD_ID_USER) || account.id;
        return userId;
    }


    render() {
        const {location, [GLOBAL_DATA.DASHBOARD_ITEMS]: items, pin, onPinFailure} = this.props;
        const now = new Date();
        const year = getQueryParamNumber(location, QUERY_PARAMS.DASHBOARD_YEAR) || now.getFullYear();
        const month = getQueryParamNumber(location, QUERY_PARAMS.DASHBOARD_MONTH) || (now.getMonth() + 1);
        const userId = this.getUserId(this.props);

        return <React.Fragment>
            <Helmet
                title={t`Štatistiky`}
            />
            <div className="full-size-height overflow-y-auto">
                <div className="d-flex m-3">
                    <div className="d-flex align-items-center">
                        <span className="mr-2"><Trans>Rok:</Trans></span>
                        <InputNumber
                            value={year}
                            step={1}
                            min={2000}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.DASHBOARD_YEAR)}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="ml-3 mr-2"><Trans>Mesiac:</Trans></span>
                        <MonthSelect
                            className="dashboard-month-select"
                            value={month}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.DASHBOARD_MONTH)}
                        />
                    </div>
                    <RightsWrapper from={RIGHTS.MANAGER}>
                        <div className="d-flex align-items-center">
                            <span className="ml-3 mr-2"><Trans>Zamestnanec:</Trans></span>
                            <EmployeesSelect
                                className="dashboard-user-select"
                                value={userId}
                                onChange={createSetQueryParamValue(location, QUERY_PARAMS.DASHBOARD_ID_USER)}
                            />
                            <NavigateButton
                                to={ROUTES.ASSIGNMENTS}
                                queryParams={{
                                    [QUERY_PARAMS.ASSIGNMENTS_ID_USER]: userId,
                                    [QUERY_PARAMS.ASSIGNMENTS_YEAR]: year,
                                }}
                            >
                                <Trans>Zobraziť úlohy a odmeny</Trans>
                            </NavigateButton>
                        </div>
                    </RightsWrapper>
                </div>
                <div className="p-3 d-flex flex-wrap">
                    { items.length > 0 ? 
                        items.map((it, idx) => {
                            return <DashboardItemDisplay
                                key={idx}
                                name={it.name}
                                label={it.label}
                                pin={pin}
                                userId={userId}
                                year={year}
                                month={month}
                                onPinFailure={onPinFailure}
                            />
                        }) :
                        <h1 className="d-inline color-disabled">
                            <Trans>Nie sú dostupné žiadne štatistiky.</Trans>
                        </h1>
                    }
                    <DashboardAssignments
                        userId={userId}
                        year={year}
                        month={month}
                        pin={pin}
                    />
                </div>
            </div>
        </React.Fragment>;
    }

}

export default pinProtectedPageHOC(
    locationHOC(
        withDataHOC([
            GLOBAL_DATA.DASHBOARD_ITEMS, 
            GLOBAL_DATA.ACCOUNT_INFO
        ])(DashboardPage)
    )
);